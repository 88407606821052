// Replace loading.gif with appropriate image
function replaceloadinggif(el) {
    if (el.attr('data-changed') != 1) {
        // Fetch image url
        var alttag = el.attr('alt')
        $.getJSON('/cms/cognito/widget/imgSrc', {
            code: alttag,
        }, function (data) {
            if (data.url) {
                el.attr('src', data.url)
            }
        })
        el.attr('data-changed', 1)
    }
}

/**
 * Save a draft from an update or create
 * @param object formelement The jQuery form element
 * @param string namespace Namespace url name
 * @param string model Model url name
 * @param object parameters List of create item parameters, e.g. for creating related items
 * @param boolean forced Autosave = 0, Force draft save = 1
 */
function cgsavedraft(formelement, namespace, model, parameters, forced) {
    if (!formelement.length) {
        // Must be trying to save draft on a form that has been closed
        return
    }
    formelement.find('.savedraftbtn i').removeClass('fa-save')
    formelement.find('.savedraftbtn i').addClass('fa-spin fa-spinner')

    // Ensure HTML editors are up to date
    if (typeof (CKEDITOR) !== 'undefined') {
        for (instance in CKEDITOR.instances) {
            CKEDITOR.instances[instance].updateElement()
        }
    }

    $.post('/cms/' + namespace + '/' + model + '/savedraft', {
        parameters: parameters,
        force: forced,
        data: formelement.serialize(),
    }, function (data) {
        formelement.find('.savedraftbtn i').removeClass('fa-spin fa-spinner')
        formelement.find('.savedraftbtn i').addClass('fa-save')
        if (data.success) {
            new Noty({
                timeout: 1000,
                theme: 'semanticui',
                type: 'success',
                text: 'Draft Saved',
            }).show()
        }
    })
}

$(document).ready(function () {
    $('body').on('click', '.collapsed-box [data-widget="collapse"]', function () {
        $(this).closest('.collapsed-box').find('.box-body').toggle()
        return false
    })
    /* All create and update forms get submitted via ajax */
    $('body').on('submit', '.ajaxform', function () {
        var submitbutton = $('input[type=submit][clicked=true]')
        if (submitbutton.hasClass('disabled')) {
            return false
        }
        submitbutton.remove()
        var formdata = $(this).serialize()
        formdata += '&' + submitbutton.attr('name') + '=' + submitbutton.val()
        var url = $(this).attr('action')
        var container = $(this).closest('.cognito-ajax-wrapper')
        var dialogid = $(this).closest('.cognito-ajax-dialog').attr('id')
        var thisdialog = false
        $.each(BootstrapDialog.dialogs, function (id, dialog) {
            if (dialogid === id) {
                thisdialog = dialog
            }
        })
        $.post(url, { data: formdata }, function (result) {
            $('.dataTable thead').trigger('dblclick')
            $('.refresh_on_ajax_save').trigger('dblclick')
            $('.ajaxnestedset').trigger('ajaxreload')
            if (result.body) {
                container.html(result.body)
            }
            else if (result.redirect) {
                if (thisdialog) {
                    thisdialog.close()
                    $.get('/cms/clearflash')
                }
                else {
                    window.location = result.redirect
                }
            }
            else if (result.ajaxload) {
                container.load(result.ajaxload, function () {
                    window.scrollTo(0, 0)
                })
            }
            else {
                container.html(result)
            }
        })
        container.html('<div class="spincenter"><i class="fa fa-spin fa-spinner"></i></div>')
        return false
    })

    /* Cancel button */
    $('body').on('click', '.ajaxform .cancel-close', function () {
        var dialogid = $(this).closest('.cognito-ajax-dialog').attr('id')
        var thisdialog = false
        $.each(BootstrapDialog.dialogs, function (id, dialog) {
            if (dialogid === id) {
                thisdialog = dialog
            }
        })
        if (thisdialog) {
            thisdialog.close()
            $.get('/cms/clearflash')
        }
        else {
            // Go back to the admin screen
            var ref = window.location.href
            var updatepos = ref.search('/update/')
            var createpos = ref.search('/create')
            var stringlength = ref.length
            var newloc = ''
            if (updatepos > 0) {
                newloc = ref.substr(0, updatepos)
            }
            else if ((createpos > 0) && ((stringlength - createpos) === 7)) {
                newloc = ref.substr(0, createpos)
            }
            if (newloc === '') {
                window.history.back()
            }
            else {
                window.location = newloc
            }
        }
    })

    $('body').on('click', 'form input[type=submit]', function () {
        $('input[type=submit]', $(this).parents('form')).removeAttr('clicked')
        $(this).attr('clicked', 'true')
    })

    function newdialog(href) {
        var hrefarr = href.split('?')
        var hreflb = hrefarr[0] + '/lb'
        if (hrefarr[1]) {
            hreflb += '?' + hrefarr[1]
        }
        BootstrapDialog.show({
            cssClass: 'cognito-ajax-dialog',
            size: BootstrapDialog.SIZE_WIDE,
            closeByBackdrop: false,
            closeByKeyboard: false,
            message: $('<div></div>').load(hreflb),
        })
    }

    $('body').on('click', '.modal-content a.nolightbox', function () {
        window.location = $(this).attr('href')
        return false
    })

    $('body').on('click', 'a.lightbox', function () {
        newdialog($(this).attr('href'))
        return false
    })

    $('body').on('click', '.dataTables_paginate a', function () {
        // Disable loading pagination links in a dialog
        return false
    })

    $('body').on('click', '.dataTable a', function () {
        // Open in new window if requested
        if ($(this).attr('target')) {
            return true
        }
        // Unless it has the nolightbox class
        if ($(this).hasClass('nolightbox')) {
            return true
        }

        // Otherwise open in a dialog
        if ($(this).closest('.cognito-ajax-wrapper').length) {
            newdialog($(this).attr('href'))
            return false
        }
    })

    // Fix datatable sizing in hidden tabs
    $('body').on('shown.bs.tab', 'a[data-toggle="tab"]', function () {
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust()
    })

    /* Select 2 */
    $('.selectize').selectize()

    $('.chzn-select').chosen({ allow_single_deselect: true })
    $('.select2-select').select2()

    $('.dashboardwidget').each(function () {
        var height = $(this).attr('data-scroll-height')
        if (height) {
            $(this).find('.box-body').slimScroll({
                height: height,
                touchScrollStep: 50,
            })
        }
    })

    /* Avoid enter to submit forms */
    $(document).on('keypress', ':input:not(textarea)', function (event) {
        return event.keyCode != 13
    })

    /* All links inside bootstrap dialogs to load inside the dialog */
    $(document).on('click', '.bootstrap-dialog-message a', function () {
        // Except if they have the noajax class
        if ($(this).hasClass('noajax')) {
            return true
        }
        // Or is a chosen select
        if ($(this).hasClass('chosen-single')) {
            return true
        }
        // Or the target is set
        if ($(this).attr('target')) {
            return true
        }
        // Or inside a ckeditor
        if ($(this).parents('.cke').length) {
            return true
        }
        // Or inside a form builder
        if ($(this).parents('.formbuilder').length) {
            return true
        }
        var link = $(this).attr('href')
        if ($(this).hasClass('needs-lb')) {
            var hrefarr = link.split('?')
            var hreflb = hrefarr[0] + '/lb'
            if (hrefarr[1]) {
                hreflb += '?' + hrefarr[1]
            }
            link = hreflb
        }
        var container = $(this).closest('.bootstrap-dialog-message-content')
        if (!container.length) {
            container = $(this).closest('.bootstrap-dialog-message')
        }
        container.html('<div class="spincenter"><i class="fa fa-spin fa-spinner"></i></div>')
        var dialogid = $(this).closest('.cognito-ajax-dialog').attr('id')
        var thisdialog = false
        $.each(BootstrapDialog.dialogs, function (id, dialog) {
            if (dialogid === id) {
                thisdialog = dialog
            }
        })
        $.get(link, function (result) {
            $('.dataTable thead').trigger('dblclick')
            $('.refresh_on_ajax_save').trigger('dblclick')
            $('.ajaxnestedset').trigger('ajaxreload')
            if (result.body) {
                container.html(result.body)
            }
            else if (result.redirect) {
                if (thisdialog) {
                    thisdialog.close()
                    $.get('/cms/clearflash')
                }
                else {
                    window.location = result.redirect
                }
            }
            else if (result.ajaxload) {
                container.load(result.ajaxload, function () {
                    window.scrollTo(0, 0)
                })
            }
            else {
                container.html(result)
            }
        })
        return false
    })

    // Handle ALL checkboxes
    $(document).on('click', '.cognito_checkbox_all', function () {
        $(this).closest('.cognito_checkbox_group').find('input[type=checkbox]').prop('checked', $(this).is(':checked'))
    })

    // Handle clicking on update items beside referenced table dropdowns
    $(document).on('click', '.cf-select-update-item', function () {
        // get id
        var item_id = $(this).closest('.input-group').find('select').val()
        var namespace = $(this).attr('data-namespace')
        var model = $(this).attr('data-model')
        newdialog('/cms/' + namespace + '/' + model + '/update/' + item_id)
    })

    // Search by code submit on enter
    $(document).on('keyup', '.search_by_code', function (ev) {
        if (ev.which != 13) {
            return
        }
        ev.preventDefault()
        $(this).closest('form').submit()
    })

    // Wiggle one signal notification
    function wiggleNotification(el) {
        el.wiggle('start', {
            multiplier: 4,
            delay: 20,
        })
        setTimeout(function () {
            el.wiggle('stop')
        }, 500)
    }

    setInterval(function () {
        wiggleNotification($('.wiggle-notification'))
    }, 10000)

    // Automatic dataTables
    $('.datatable-auto').DataTable({
        dom: '<\'row\'<\'col-sm-6\'l><\'col-sm-6\'f>>'
            + '<\'row\'<\'col-sm-12\'tr>>'
            + '<\'row\'<\'col-sm-12 text-right\'B>>'
            + '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        buttons: [
            'copy',
            'csv',
            'print',
        ],
    })
})
